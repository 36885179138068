
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import StaticSection from '@/models/StaticSection';

@Component
export default class StaticsPage extends Vue {
    @Watch('name')
    onNameChanged(): void {
        this.initialSections();
    }

    @Prop(String)
    readonly name!: string;

    get resourcePath(): string {
        const baseAddress = process.env.VUE_APP_STATIC_FILED_PUBLIC;
        if (baseAddress) return `${baseAddress}/${this.name}.json`;
        return `${this.name}.json`;
    }

    sections: Array<StaticSection> = [];

    initialSections(): void {
        this.sections = [];
        this.$store.commit('setLoading', true);
        fetch(`${this.resourcePath}`)
            .then((responce) => responce.json())
            .then((data) => {
                this.$store.commit('setLoading', false);
                const sections = (data as Array<StaticSection>).map(x => new StaticSection(x));
                this.sections = sections;
            });
    }

    mounted(): void {
        this.initialSections();
    }
}
