export default class StaticSection {
    header?: string;
    title?: string;
    content?: Array<StaticSection> | string;

    constructor(input: StaticSection) {
        Object.assign(this, input);
    }

    get isContentString(): boolean {
        return typeof this.content === 'string';
    }
    get onlyHeader(): boolean {
        return !this.title && !this.content;
    }
}